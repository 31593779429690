





































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { Charity } from '@/models/store';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { observeUser } from '@/firebase-app';
import { socialService } from '@/services';

@Component
export default class ThankYouPage extends Vue {
  @Mutation private setInitiated!: () => void;
  @Getter private newCharities!: Charity[];
  @Getter private nextPaymentDate!: string;
  @Getter private isInitiated!: string;

  public created() {
    observeUser(async user => {
      if (user) {
        const profileResponse = await socialService.getUserProfile();
        if (profileResponse) {
          await this.$store.dispatch('handleProfileResponse', profileResponse);
        }
      }

      this.setInitiated();
    });
  }

  private get donationChanged() {
    return this.$route.query?.change == 'true';
  }

  private get charityNames() {
    const names = this.newCharities.map(c => c.name);
    if (!names || !names.length) {
      return '';
    }

    if (names.length === 1) {
      return ` ${names[0]}`;
    }

    const last = names.pop();
    return ` ${names.join(', ')} en ${last}`;
  }

  private get nextPayment() {
    return format(new Date(this.nextPaymentDate || '2021-05-06'), 'PPPP', {
      locale: nl,
    });
  }

  private get shareUrl() {
    return 'https://letsbesocial.io/goede-doelen'; // window.config.baseUrl;
  }

  private get shareCopy() {
    return encodeURIComponent(
      `Ik heb net supermakkelijk een donatie gedaan aan ${this.charityNames}. Doe jij ook mee?`
    );
  }

  private get shareAll() {
    return `${this.shareCopy}%0A${this.shareUrl}`;
  }
}
