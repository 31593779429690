




















































































































































import { Component, Watch } from 'vue-property-decorator';
import ConfirmationRow from '@/components/confirm/confirmation-row.vue';
import Loader from '@/components/common/loader.vue';
import { socialService } from '@/services';
import { ApiAmount, SocialSubscriptionStatus } from '@/models/api';
import { Getter, Mutation } from 'vuex-class';
import { Charity, TempConfirmationState, TempShares } from '@/models/store';
import { formatToCurrency } from '@/utils/string-utils';
import AuthMixin from '@/pages/auth/auth-mixin';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

@Component({
  components: { ConfirmationRow, Loader },
})
export default class ConfirmPage extends AuthMixin {
  @Getter private newCharities!: Charity[];
  @Getter private currentCharities!: Charity[];
  @Getter private currentAmount!: number;
  @Getter private tempConfirmationState!: TempConfirmationState;
  @Getter private nextPaymentDate!: string;
  @Getter private subscriptionStatus!: SocialSubscriptionStatus;
  @Mutation private setTempConfirmationState!: (payload: any) => void;
  @Mutation private clearSubscriptionActions!: () => void;
  @Mutation private setIdealClientSecret!: (clientSecret: string) => void;

  private initialAmount = 20;
  private platformCostsPercentage = 5;
  private amount = this.initialAmount;
  private confirmDisabled = false;
  private error: string | null = null;
  private redirecting = false;

  private get allShares() {
    // @ts-ignore
    return this.newCharities.reduce((t, s) => (t += s.shares), 0);
  }

  private mounted() {
    // do something with initial amount
    this.amount = this.currentAmount ?? this.initialAmount;
    this.setPreviousSavedState();
    this.redirecting = false;
  }

  private add() {
    let newAmount = this.amount + 5 - (this.amount % 5);
    if (newAmount > 150) {
      newAmount = 150;
    }

    this.amount = newAmount;
    this.$gtag.event('add-amount', {
      event_label: 'amount',
      value: this.amount,
    });
  }

  private subtract() {
    let newAmount = this.amount - (this.amount % 5 || 5);
    if (newAmount < 5) {
      newAmount = 5;
    }

    this.amount = newAmount;
    this.$gtag.event('subtract-amount', {
      event_label: 'amount',
      value: this.amount,
    });
  }

  @Watch('amount')
  private amountSaveGuard() {
    if (this.amount < 5) {
      this.amount = 5;
    }

    if (this.amount > 150) {
      this.amount = 150;
    }
  }

  private get isCancelled() {
    return this.subscriptionStatus === SocialSubscriptionStatus.Canceled;
  }

  private get isFirstTime() {
    return !this.currentCharities?.length || !this.nextPaymentDate;
  }

  private get platformCosts() {
    return Math.round(this.amount * this.platformCostsPercentage) / 100;
  }

  private get formattedPlatformCosts() {
    return formatToCurrency(this.platformCosts);
  }

  private get formattedTotal() {
    return formatToCurrency(this.amount + this.platformCosts);
  }

  private setPreviousSavedState() {
    const savedState = this.tempConfirmationState;
    if (
      !savedState ||
      !savedState.amount ||
      !savedState.tempShares ||
      Object.keys(savedState.tempShares).length === 0
    ) {
      return;
    }

    // Set the saved amount
    this.amount = savedState.amount;

    // set the saved shares
    Object.entries(savedState.tempShares).forEach(([key, value]) => {
      const obj = this.newCharities.find(c => c.id === key);
      obj && (obj.shares = value || 1);
    });
  }

  private async confirm() {
    this.error = null;
    this.confirmDisabled = true;
    this.setTempConfirmationState(null);

    const amount: ApiAmount = {
      value: this.amount.toFixed(2),
      currency: 'EUR',
    };

    const result = await socialService.checkout(amount, this.newCharities);
    if (!result) {
      this.error = 'Er ging iets mis, probeer het later nog een keer';
      this.confirmDisabled = false;
      return;
    }

    // Show redirecting
    this.redirecting = true;

    // Redirect
    await this.clearSubscriptionActions();
    if (result.clientSecret) {
      this.setIdealClientSecret(result.clientSecret);
      this.$router.push({ name: 'IDealPage' });
    } else {
      this.$router.push({ name: 'ThankYouPage', query: { change: `${!this.isCancelled}` } });
    }
  }

  private signInFirst() {
    // Save current state first
    const tempShares = this.newCharities.reduce((r, c) => {
      r[c.id] = c.shares || 1;
      return r;
    }, {} as TempShares);

    this.setTempConfirmationState({
      amount: this.amount,
      tempShares,
    });

    // Redirect ro login
    this.$router.push({ name: 'LoginPage', query: { redirectTo: 'confirm' } });
  }

  private get showParts() {
    return this.newCharities.length > 1;
  }

  private get nextPayment() {
    return format(new Date(this.nextPaymentDate), 'PPPP', {
      locale: nl,
    });
  }
}
