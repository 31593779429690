export enum SocialSubscriptionStatus {
  None = 'NONE',
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Error = 'ERROR',
}

export interface ApiUserInfo {
  firstName: string;
  lastName: string;
  dob: string;
  nationality: string;
}

export interface ApiProfile {
  email: string;
  donationAmount: ApiAmount;
  subscriptionAmount: ApiAmount;
  platformCostPercentage: number;
  charities?: ApiCharity[];
  subscriptionStatus: SocialSubscriptionStatus;
  nextPaymentDate?: string;
  profile: ApiUserInfo;
}

export interface ApiCharity {
  id: string;
  name: string;
  shares: number;
}

export interface ApiAmount {
  value: string;
  currency: string;
}

type PaymentStatus = 'paid' | 'pending' | 'canceled' | 'expired' | 'failed';
export interface ApiDonationHistoryRecord {
  paymentCreatedDate: string;
  paidDate?: string;
  charities: ApiCharity[];
  donation: ApiAmount;
  status: PaymentStatus;
  paymentId: string;
}
