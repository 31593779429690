


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Charity } from '@/models/store';
import { formatToCurrency } from '@/utils/string-utils';

@Component
export default class ConfirmationRow extends Vue {
  @Prop() charity!: Charity;
  @Prop() totalShares!: number;
  @Prop() totalAmount!: number;
  @Prop() showParts!: boolean;

  private subtractShares() {
    if (!this.charity.shares || this.charity.shares <= 0 || this.totalShares === 1) {
      return;
    }

    this.charity.shares--;
    this.$gtag.event('subtract-shares', {
      event_category: this.charity.name,
      event_label: 'shares',
      value: this.charity.shares,
    });
  }

  private addShares() {
    this.charity.shares++;
    this.$gtag.event('add-shares', {
      event_category: this.charity.name,
      event_label: 'shares',
      value: this.charity.shares,
    });
  }

  private get amountOfShare() {
    return formatToCurrency((this.totalAmount / this.totalShares) * this.charity.shares);
  }
}
