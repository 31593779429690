const formatter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
});

export function formatToCurrency(amount: number | string) {
  const number = typeof amount === 'string' ? parseInt(amount, 10) : amount;
  return formatter.format(number);
}
